import {useLegalDocument} from '@hconnect/common/hooks/legal/useLegalDocuments'
import {useLatestDocumentVersion} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Close} from '@material-ui/icons'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {LanguagePicker} from './LanguagePicker'
import {LegalDocumentRenderer} from './LegalDocumentRenderer'

const logoAspectRatio = 71 / 12
const logoHeight = 18

export enum Product {
  OnSite = 'OnSite',
  Cockpit = 'Cockpit',
  Hub = 'Hub',
  TruckMonitor = 'TruckMonitor',
  HConnect = 'HConnect',
  UserManagement = 'UserManagement',
  HCEM = 'HCEM',
  HProduce = 'HProduce',
  PREDICT = 'PREDICT',
  Maintain = 'Maintain',
  POM = 'POM',
  Settings = 'Settings'
}

const useStyles = makeStyles(() =>
  createStyles({
    sideNav: {
      backgroundColor: 'rgb(247, 247, 247)'
    },
    link: {
      color: 'inherit',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    legalDocumentContent: {
      '& h1': {
        fontSize: '1.5em',
        lineHeight: 'initial'
      }
    },
    root: {
      justifyContent: 'center'
    },
    scroller: {
      flexGrow: 0
    }
  })
)

interface Props {
  documentType: LegalDocumentType
  onClose?: () => void
  onAccept: () => void
  onLanguageChange?: (languageCode: string) => void
  country?: string
  preferredLanguage?: string
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>
  showFooter: boolean
  hideAcceptanceTitle: boolean
  // When open from mobile app, app will pass paddings (safe area, notch etc.)
  paddingTop?: number | undefined
  paddingBottom?: number | undefined
}

export enum LegalDocumentType {
  NONE,
  PRIVACY,
  TERMS,
  IMPRINT,
  AFFILIATED
}

interface CloseButtonProps {
  onClose: () => void
  disabled?: boolean
}

const useButtonStyles = makeStyles(() => ({
  closeButton: {}
}))

const CloseButton: React.FC<CloseButtonProps> = ({onClose, disabled = false}) => {
  const classes = useButtonStyles()
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <div className={classes.closeButton}>
        <IconButton
          onClick={() => onClose()}
          style={{border: 'none', boxShadow: 'none', padding: 0, margin: 0}}
          data-test-id="modal-close-button"
          disabled={disabled}
        >
          <Close />
        </IconButton>
      </div>
    </Box>
  )
}

const queryDocumentType = (documentType) => {
  if (documentType === LegalDocumentType.TERMS) return 'tc'
  else if (documentType === LegalDocumentType.PRIVACY) return 'dpp'
  else if (documentType === LegalDocumentType.AFFILIATED) return 'ac'
  else return 'imprint'
}

// eslint-disable-next-line complexity
export const TermsAndPrivacyModal: React.FC<Props> = ({
  documentType: documentTypeFromProp,
  onClose,
  onAccept,
  onLanguageChange,
  country,
  preferredLanguage,
  logo: Logo,
  showFooter,
  hideAcceptanceTitle = false,
  paddingTop = 0,
  paddingBottom = 0
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [documentType, setDocumentType] = useState<LegalDocumentType>(
    documentTypeFromProp ?? LegalDocumentType.TERMS
  )
  const imprintTitle = country === 'AU' ? t('legal.disclaimer') : t('legal.imprint')

  useEffect(() => {
    setDocumentType(documentTypeFromProp)
  }, [documentTypeFromProp])

  const [requestedDocumentLanguage, setRequestedDocumentLanguage] = useState<string>(
    preferredLanguage || 'en'
  )

  useEffect(() => {
    setRequestedDocumentLanguage(preferredLanguage || 'en')
  }, [preferredLanguage])

  const handleLanguageChange = (languageCode: string) => {
    setRequestedDocumentLanguage(languageCode)
    onLanguageChange && onLanguageChange(languageCode)
  }

  const isTerms = documentType === LegalDocumentType.TERMS

  const {queryInfo, language: legalDocumentLanguage} = useLegalDocument(
    queryDocumentType(documentType),
    country,
    requestedDocumentLanguage
  )

  const latestVersionQueryInfo = useLatestDocumentVersion(isTerms ? 'tc' : 'dpp', country)
  const availableLanguages =
    latestVersionQueryInfo?.lastAvailableDocumentVersion?.availableLanguages

  if (queryInfo.isLoading || latestVersionQueryInfo?.isLoading) {
    return <CircularProgress />
  }

  const renderFooter = () => {
    if (!showFooter) {
      return null
    }

    return (
      <Box
        data-test-id="user-acceptance-modal-footer"
        display="flex"
        justifyContent="flex-end"
        mt={2}
        mb={paddingBottom > 0 ? `${paddingBottom}px` : 2}
        mx={2}
        pb={isWideScreen ? 0 : 15}
      >
        <Button
          data-test-id="user-acceptance-legal-accept-button"
          type="button"
          onClick={() => onAccept()}
          color="primary"
        >
          {t('legal.accept')}
        </Button>
      </Box>
    )
  }

  const renderLanguageSelector = () => {
    if (!legalDocumentLanguage || !availableLanguages || availableLanguages.length < 2) {
      return null
    }

    return (
      <LanguagePicker
        availableLanguages={availableLanguages}
        selectLanguage={handleLanguageChange}
        country={country}
        currentLanguage={legalDocumentLanguage}
      />
    )
  }

  const getTitle = () => {
    switch (documentType) {
      case LegalDocumentType.TERMS:
        return t('legal.termsAndConditions')
      case LegalDocumentType.PRIVACY:
        return t('legal.privacyPolicy')
      case LegalDocumentType.IMPRINT:
        return imprintTitle
      default:
        return ''
    }
  }

  return (
    <Modal
      open={documentType !== LegalDocumentType.NONE}
      onClose={onClose}
      style={{padding: isWideScreen ? 100 : 0}}
    >
      <Paper elevation={4}>
        <Grid
          container
          data-test-id="user-acceptance-terms-page"
          style={{
            height: isWideScreen ? 'calc(100vh - 200px)' : '100vh',
            overflow: 'hidden',
            flexWrap: 'nowrap',
            flexDirection: isWideScreen ? 'row' : 'column'
          }}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={2}
            data-test-id="user-acceptance-side"
            className={classes.sideNav}
            style={{
              flex: isWideScreen ? 1 : 0,
              paddingTop: `${paddingTop}px`
            }}
          >
            <Box
              data-test-id="user-acceptance-side-logo"
              display="flex"
              flexDirection="column"
              mt={1}
            >
              <Box flexDirection="column" alignItems="stretch" display="flex">
                <Box
                  mx={2}
                  my={isWideScreen ? 2 : 1}
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box flex={1} />
                  <Logo
                    data-test-id="terms-and-privacy-logo"
                    height={logoHeight}
                    width={logoHeight * logoAspectRatio}
                  />
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    lineHeight="normal"
                  >
                    {isWideScreen ? null : (
                      <>
                        <div />
                        {renderLanguageSelector()}
                      </>
                    )}
                    {!isWideScreen && !showFooter && !!onClose && (
                      <CloseButton onClose={onClose} disabled={false} />
                    )}
                  </Box>
                </Box>
                <Box>
                  <Tabs
                    value={documentType}
                    classes={{root: classes.root, scroller: classes.scroller}}
                    aria-label="legal-documents-type-tabs"
                    orientation={isWideScreen ? 'vertical' : 'horizontal'}
                    variant={isWideScreen ? 'fullWidth' : 'scrollable'}
                  >
                    <Tab
                      label={t('legal.termsAndConditions')}
                      data-test-id="legal-document-tc-tab"
                      onClick={() => setDocumentType(LegalDocumentType.TERMS)}
                      value={LegalDocumentType.TERMS}
                      wrapped={true}
                    />
                    <Tab
                      label={t('legal.privacyPolicy')}
                      data-test-id="legal-document-dpp-tab"
                      onClick={() => setDocumentType(LegalDocumentType.PRIVACY)}
                      value={LegalDocumentType.PRIVACY}
                      wrapped={true}
                    />
                    <Tab
                      label={imprintTitle}
                      data-test-id="legal-document-imprint-tab"
                      onClick={() => setDocumentType(LegalDocumentType.IMPRINT)}
                      value={LegalDocumentType.IMPRINT}
                      wrapped={true}
                    />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={10}
            data-test-id="user-acceptance-content"
            style={{display: 'flex', flex: 1, overflowY: 'hidden'}}
          >
            <Box display="flex" flexDirection="column" style={{width: '100%'}}>
              {isWideScreen && (
                <>
                  <Box
                    data-test-id="user-acceptance-title"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px={2}
                    py={2}
                  >
                    {!hideAcceptanceTitle ? (
                      <Typography>{t('legal.acceptConditionsOfUse')}</Typography>
                    ) : (
                      <div />
                    )}
                    {!showFooter && !!onClose && <CloseButton onClose={onClose} disabled={false} />}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    pr={4}
                  >
                    <h1>{getTitle()}</h1>
                    {renderLanguageSelector()}
                  </Box>
                </>
              )}
              <Box overflow="auto">
                <LegalDocumentRenderer body={queryInfo.data} />
                {renderFooter()}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}
