import {
  BrowserBackendSelector,
  createPublicHttpClient,
  getDefaultDFBackends
} from '@hconnect/apiclient'

const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())

export const api = createPublicHttpClient({
  backendSelector
})
