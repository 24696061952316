import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {LightShell} from './Components/LightShell'
import {Router} from './Router'
import {routes} from './routes'

export const App: React.FC = () => {
  const {t} = useTranslation()

  useEffect(() => {
    document.title = t('legal.title')
  })

  return (
    <LightShell
      excludedRoutes={[routes.MobileLegalAcceptancePage, routes.MobileHeadlessDocumentPage]}
    >
      <Router />
    </LightShell>
  )
}
