import {Box} from '@material-ui/core'
import {toUpper} from 'lodash'
import React from 'react'

import {svgFlagSources} from '../../../common'

type Props = {
  country?: string
  rounded?: boolean
  onClick?: (event: any) => void
}

export const Flag: React.FC<Props> = ({country, rounded, onClick}) => {
  const flagPathByCountryCode = (countryCode?: string): string => {
    return svgFlagSources[toUpper(countryCode)]
  }

  if (!flagPathByCountryCode(country)) {
    return null
  }
  return (
    <Box display="inline-flex">
      <img
        src={flagPathByCountryCode(country)}
        style={rounded ? {clipPath: 'circle(42% at center)'} : {}}
        onClick={onClick}
        alt={country}
      />
    </Box>
  )
}
