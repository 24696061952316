import {EnvAppInfoContainer, OssAttributionLink} from '@hconnect/uikit'
import LogoHConnect from '@hconnect/uikit/src/common/assets/HConnectLogo_colored_white_text.svg'
import {Box, Grid, makeStyles} from '@material-ui/core'
import React, {useState} from 'react'
import {matchPath} from 'react-router-dom'

import {HeaderLogo} from './HeaderLogo'

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'linear-gradient(90deg, #0f4b73 0%, #18699f 40%,#18699f 60%, #0f4b73 100%)',
    color: theme.palette.primary.contrastText,
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  paper: {
    borderRadius: '8px'
  },
  header: {
    height: '68px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 8px -2px #000000',
    [theme.breakpoints.down('xs')]: {
      height: '40px'
    }
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center'
  },
  footerContainer: {
    fontSize: 12,
    borderTop: '1px solid #97979733',
    marginBottom: theme.spacing(2)
  },
  footerLogoContainer: {
    margin: theme.spacing(1)
  }
}))

export const LightShell = ({children, excludedRoutes}) => {
  const classes = useStyles()
  const [showVersion, setShowVersion] = useState(false)

  const hideShell = excludedRoutes.reduce((acc, route) => {
    return matchPath(window.location.pathname, {path: route, strict: true}) !== null ? true : acc
  }, false)

  if (hideShell) {
    return children
  }

  return (
    <Box className={classes.container}>
      <Grid container justify="center" className={classes.header}>
        <Grid item xl={7} md={9} xs={11} className={classes.headerContent}>
          <HeaderLogo />
        </Grid>
      </Grid>
      {children}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.footerContainer}
        onClick={() => setShowVersion((prev) => !prev)}
      >
        <img alt="Logo HConnect" className={classes.footerLogoContainer} src={LogoHConnect} />
        {showVersion && <EnvAppInfoContainer />}
        <OssAttributionLink />
      </Box>
    </Box>
  )
}
