import React from 'react'
import {Helmet} from 'react-helmet-async'

import appleIcon114 from './apple-touch-icon-114x114.png'
import appleIcon120 from './apple-touch-icon-120x120.png'
import appleIcon144 from './apple-touch-icon-144x144.png'
import appleIcon152 from './apple-touch-icon-152x152.png'
import appleIcon180 from './apple-touch-icon-180x180.png'
import appleIcon57 from './apple-touch-icon-57x57.png'
import appleIcon60 from './apple-touch-icon-60x60.png'
import appleIcon72 from './apple-touch-icon-72x72.png'
import appleIcon76 from './apple-touch-icon-76x76.png'
// import androidIcon36 from './android-icon-36x36.png'
// import androidIcon48 from './android-icon-48x48.png'
// import androidIcon72 from './android-icon-72x72.png'
// import androidIcon96 from './android-icon-96x96.png'
// import androidIcon144 from './android-icon-144x144.png'
// import androidIcon192 from './android-icon-192x192.png'
// import favIcon96 from './favicon-96x96.png'
import browserconfig from './browserconfig.xml'
import favIcon16 from './favicon-16x16.png'
import favIcon32 from './favicon-32x32.png'
import favIconFallback from './favicon.ico'
import manifest from './site.webmanifest'

export const Head = () => {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57} />
      <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60} />
      <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72} />
      <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76} />
      <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114} />
      <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
      <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />
      {/* <link rel="icon" type="image/png" sizes="36x36" href={androidIcon36} />
      <link rel="icon" type="image/png" sizes="48x48" href={androidIcon48} />
      <link rel="icon" type="image/png" sizes="72x72" href={androidIcon72} />
      <link rel="icon" type="image/png" sizes="96x96" href={androidIcon96} />
      <link rel="icon" type="image/png" sizes="144x144" href={androidIcon144} />
      <link rel="icon" type="image/png" sizes="192x192" href={androidIcon192} /> */}
      {/* <link rel="icon" type="image/png" sizes="96x96" href={favIcon96} />*/}
      <link rel="icon" type="image/png" sizes="32x32" href={favIcon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favIcon16} />
      <link rel="manifest" href={manifest} />
      <link rel="shortcut icon" href={favIconFallback} />
      <meta name="msapplication-config" content={browserconfig} />
      <meta name="msapplication-TileColor" content="#00374D" />
      <meta name="msapplication-TileImage" content="%PUBLIC_URL%/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="format-detection" content="telephone=no" />
    </Helmet>
  )
}
