import {Paper, Grid, Box, Typography} from '@material-ui/core'
import {Theme, makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {getLicenses} from '../utils/licenses'

const useStyles = makeStyles((theme: Theme) => ({
  licensePrimary: {
    ...theme.typography.body1,
    fontWeight: 800
  },
  licenseSecondary: {
    color: theme.palette.secondary.dark
  },
  licenseContainer: {
    marginBottom: theme.spacing(3)
  },
  licenseText: {
    marginBottom: theme.spacing()
  },
  link: {
    wordBreak: 'break-word'
  }
}))

interface LicenseProps {
  moduleName: string
  name: string | null
  version: string | null
  spdx: string
  licenseText: string
  copyright: string
  repository: string
}

export const LicensesList: React.FC = () => {
  const classes = useStyles({})
  const licenses = getLicenses()

  const licensesContent = licenses.map((license: LicenseProps) => (
    <div key={license.moduleName} style={{padding: 30}}>
      <Grid className={classes.licenseContainer} container spacing={2}>
        <Grid item lg={3}>
          <div className={classes.licensePrimary}>{license.name}</div>
          <div className={classes.licenseSecondary}>{license.version}</div>
          <div className={classes.licenseSecondary}>{license.spdx}</div>
          <div className={classes.licenseSecondary}>
            <a href={license.repository} className={classes.link} target="_blank" rel="noreferrer">
              {license.repository}
            </a>
          </div>
        </Grid>
        <Grid item lg={9}>
          <Typography align={'justify'} className={classes.licenseText}>
            {license.licenseText}
          </Typography>
          <div>{license.copyright}</div>
        </Grid>
      </Grid>
    </div>
  ))

  return (
    <Paper elevation={2}>
      <Box p={2}>{licensesContent}</Box>
    </Paper>
  )
}
