import {AxiosInstance} from 'axios'
import React from 'react'

export const ApiContext = React.createContext<ApiContext | undefined>(undefined)
ApiContext.displayName = 'ApiContext'

type ApiContext = {
  secureApi: AxiosInstance | null
  publicApi: AxiosInstance
}

export const ApiProvider: React.FC<ApiContext> = (props) => {
  const {secureApi, publicApi, ...rest} = props
  return <ApiContext.Provider value={{secureApi, publicApi}} {...rest} />
}

export const useApi = () => {
  const context = React.useContext(ApiContext)
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider')
  }
  return context
}

export const useSecureApi = () => {
  const {secureApi} = useApi()
  if (secureApi === null) {
    throw new Error('useSecureApi must be used within a ApiProvider that provided secureApi')
  }
  return secureApi
}
