import {Grid, Paper, makeStyles, Box, Typography} from '@material-ui/core'
import React, {useEffect} from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px'
    }
  },
  paper: {
    borderRadius: '8px'
  },
  h1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  h2: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  }
}))

export const Page = ({children, header, subHeader = ''}) => {
  const classes = useStyles()

  return (
    <Grid container justify="center">
      <Grid item xl={7} md={9} xs={11}>
        <Box className={classes.container}>
          <Box mr={2} pt={2} mb={2}>
            <Typography data-test-id="legal-documents-title" variant="h2" className={classes.h2}>
              {subHeader}
            </Typography>
            <Typography variant="h1" className={classes.h1} data-test-id="legal-documents-country">
              {header}
            </Typography>
          </Box>

          <Paper className={classes.paper}>{children}</Paper>
        </Box>
      </Grid>
    </Grid>
  )
}
