import type {LegalDocumentsType} from '@hconnect/common/hooks/legal/types'
import {useTranslation} from '@hconnect/uikit'
import {Box, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router-dom'

import {Page} from '../Components/Page'

const useStyles = makeStyles((theme) => {
  return {
    policiesLinkContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        flexDirection: 'row'
      }
    },
    documentContainer: {
      textDecoration: 'none',
      color: '#002E4D',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2)
    },
    link: {
      textDecoration: 'none',
      color: '#29aaff'
    }
  }
})

export const LandingPage = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  const availableDocuments: LegalDocumentsType[] = ['dpp', 'tc', 'imprint', 'ac']

  return (
    <Page header={t('landingPage.policies')}>
      <Box className={classes.policiesLinkContainer}>
        {availableDocuments.map((documentType: LegalDocumentsType) => {
          return (
            <Box key={documentType} className={classes.documentContainer}>
              <Typography variant="h3">
                {t(`landingPage.documentHeader.${documentType}`)}
              </Typography>
              <Box style={{}}>{t(`landingPage.documentDescription.${documentType}`)}</Box>
              <Link
                key={documentType}
                className={classes.link}
                to={`/policies/${documentType}`}
                data-test-id={`landing-link-${documentType}`}
              >
                <Box style={{}}>{t(`landingPage.documentLink.${documentType}`)}</Box>
              </Link>
            </Box>
          )
        })}
      </Box>
    </Page>
  )
}
