import React from 'react'
import {BrowserRouter, HashRouter, Route} from 'react-router-dom'

import {CountriesPage} from './Pages/CountriesPage'
import {LandingPage} from './Pages/LandingPage'
import {LegalDocumentPage} from './Pages/LegalDocumentPage'
import {LicensesPage} from './Pages/LicensesPage'
import {MobileHeadlessDocumentPage} from './Pages/mobile/MobileHeadlessDocumentPage'
import {MobileLegalAcceptancePage} from './Pages/mobile/MobileLegalAcceptancePage'
import {routes} from './routes'

export const Router: React.FC = () => {
  const usesHtmlFile = /\.html/.test(window.location.pathname)
  const SelectedRouter = usesHtmlFile ? HashRouter : BrowserRouter

  return (
    // @ts-expect-error FIXME
    <SelectedRouter>
      <Route path={[routes.LandingPage]} exact render={() => <LandingPage />} />
      <Route path={[routes.DocumentPerCountryPage]} exact render={() => <CountriesPage />} />
      <Route path={[routes.DocumentPage]} render={() => <LegalDocumentPage />} />
      <Route path={[routes.LicensesPage]} render={() => <LicensesPage />} />
      <Route
        path={[routes.MobileLegalAcceptancePage]}
        exact
        render={() => <MobileLegalAcceptancePage />}
      />
      <Route
        path={[routes.MobileHeadlessDocumentPage]}
        exact
        render={() => <MobileHeadlessDocumentPage />}
      />
    </SelectedRouter>
  )
}
