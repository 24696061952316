import {createStyles, makeStyles, useTheme} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    legalDocumentContent: {
      '& h1': {
        fontSize: '1.5em',
        lineHeight: 'initial'
      }
    }
  })
)

export const LegalDocumentRenderer: React.FC<{body?: string | null}> = ({body}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div
      className={classes.legalDocumentContent}
      data-test-id="legal-document-content"
      style={{
        overflowX: 'hidden',
        padding: `0 ${theme.spacing(2)}px`
      }}
      // html comes from domain owned by HC. Attacker would have to have access to our GitLab
      // and in that case, there are easier methods to compromise our applications, e.g. add
      // something directly to /public/index.html of any of our products. MD Renderer is also
      // using dangerouslySetInnerHTML under the hood. If this is a concern, we might use some-
      // thing like https://github.com/cure53/DOMPurify.
      dangerouslySetInnerHTML={{__html: body ?? ''}}
    />
  )
}
