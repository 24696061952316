import type {LegalDocumentsType} from '@hconnect/common/hooks/legal/types'
import {useLegalDocumentsVersions} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {useTranslation, Flag} from '@hconnect/uikit'
import {Box, CircularProgress, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import {Link, useParams} from 'react-router-dom'

import {Page} from '../Components/Page'
import {WorldMap} from '../Components/WorldMap'

type CountriesPageParams = {
  type: LegalDocumentsType
}

const useStyles = makeStyles((theme) => ({
  worldWrapper: {
    marginBottom: theme.spacing(1)
  },
  subheader: {
    fontSize: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px'
    },
    textAlign: 'center',
    textTransform: 'none',
    marginBottom: theme.spacing(1),
    fontWeight: 'normal'
  },
  grid: {
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      margin: `${theme.spacing(5)}px 0`
    }
  },
  gridContainer: {
    borderTop: '1px solid #aeaeae45',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    }
  }
}))

export const CountriesPage: React.FC = () => {
  const {t} = useTranslation()
  const versions = useLegalDocumentsVersions()
  const {type} = useParams<CountriesPageParams>()
  const classes = useStyles()

  if (versions.isLoading) {
    return (
      <Box justifyContent="center" alignItems="center" display="flex" height="90vh">
        <CircularProgress />
      </Box>
    )
  }
  if (!versions.data) {
    // TODO: gracefully handle
    return null
  }

  const countries = Object.keys(versions.data.hconnect[type])

  return (
    <Page header={t(`legalDocumentPages.${type}.header`)}>
      <Box pt={2} pb={2} m={2}>
        <Box
          ml={2}
          mr={2}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Box className={classes.worldWrapper} display={{xs: 'none', sm: 'flex'}} marginTop={3}>
            <WorldMap height={129} width={129} />
          </Box>
          <Box width={{sm: '50%'}}>
            {/* TODO: size based on brakpoints - smaller for phones */}
            <Typography variant="h2" className={classes.subheader}>
              {t(`legalDocumentPages.${type}.subheader`)}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent={{xs: 'stretch', sm: 'center'}}
          className={classes.gridContainer}
        >
          <Box display="grid" className={classes.grid}>
            {countries.map((country) => (
              <Box key={country} alignContent="space-between">
                <Link
                  style={{textDecoration: 'none', color: '#002E4D'}}
                  to={`/policies/${type}/${country}`}
                  key={country}
                  data-test-id={`dpp-link-${country}`}
                >
                  <Box display="flex" justifyContent="flex-start" mb={1} mr={1}>
                    <Flag country={country} rounded={true} />
                    <Box ml={1}>{t(`countries.${country.toUpperCase()}`)}</Box>
                  </Box>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Page>
  )
}
