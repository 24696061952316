import {ReactComponent as HConnectLogo} from '@hconnect/uikit/src/common/assets/HConnectLogo_colored.svg'
import {Theme, useMediaQuery} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    cursor: 'pointer',
    alignItems: 'center'
  },
  containerSmall: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: theme.spacing(6),
    padding: '8px 0'
  }
}))

export const HeaderLogo: React.FC = () => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <div className={classes.containerSmall}>
        <Link to="/" onClick={() => (window.location.href = '/')} data-test-id="hconnect-logo">
          <HConnectLogo
            data-test-id="terms-and-privacy-logo"
            height={isSmallScreen ? 15 : 45}
            width={isSmallScreen ? 80 : 120}
            color="#000"
          />
        </Link>
      </div>
    </div>
  )
}
