import {makeStyles} from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    '& > picture': {
      position: 'relative',
      height: '3em',
      '&:not(:last-child):after': {
        content: '""',
        position: 'absolute',
        height: 'calc(100% - 1em)',
        margin: '.5em 0',
        borderRight: '1px solid #e7e7e7'
      },
      '& *': {
        height: '3em',
        padding: '0 1em'
      }
    }
  }
}))

interface LogosProps {
  children: any
  className?: string
  style?: any
  onClick?: () => void
}

const Logos: React.FC<LogosProps> = ({children, className: classNameProp, style, ...props}) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.container, classNameProp)} style={style} {...props}>
      {Array.isArray(children) ? (
        children.map(
          (child, index) => child && <picture key={child.key || `logo-${index}`}>{child}</picture>
        )
      ) : (
        <picture>{children}</picture>
      )}
    </div>
  )
}

export default Logos
