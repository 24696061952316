import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import {withTheme} from '@hconnect/uikit'
import {CircularProgress} from '@material-ui/core'
import i18next from 'i18next'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider} from 'react-i18next'
import {QueryClient, QueryClientProvider} from 'react-query'

import {api} from './api'
import {App} from './App'
import Localization from './Localization'
import {Head} from './meta/Head'

import 'core-js/stable'

import 'regenerator-runtime/runtime'
import {BrowserRouter} from 'react-router-dom'

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

void Localization()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const render = (Component) => {
  const ThemedApp = withTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh',
            backgroundColor: 'white',
            background: 'white'
          }
        }
      }
    }
  })(Component)

  ReactDOM.render(
    <Suspense fallback={<CircularProgress />}>
      <ApiProvider secureApi={null} publicApi={api}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18next}>
            <HelmetProvider>
              <BrowserRouter>
                <Head />
                <ThemedApp />
              </BrowserRouter>
            </HelmetProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </ApiProvider>
    </Suspense>,
    document.getElementById('root')
  )
}

render(App)
