import licenseOutput from '../licenses.json'

export const getLicenses = () => {
  const keys = Object.keys(licenseOutput)
  return keys.map((moduleName: string) => {
    const moduleDetail = licenseOutput[moduleName]
    const regex = /^(.*)@(.*)$/gm
    const result = regex.exec(moduleName)
    const {copyright, licenses, repository, licenseText} = moduleDetail
    return {
      moduleName,
      name: result && result[1],
      version: result && result[2],
      spdx: licenses,
      licenseText,
      copyright,
      repository
    }
  })
}
